<template>
    <div class="m-3 d-flex flex-column">
        <!-- Dropdown for User Profile -->
        <TenantHeader/>
        <div class="table-responsive">
            <datatable
                :isLoading.sync="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
            />
        </div>
        <CommonModal ref="requestFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
            <template v-slot:CommonModalTitle>
                {{ $t('request_form').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <request-form :formId="formId"  :reservationData=formData @requestFormSuccess="requestFormSuccess"
                              v-if="formProcess == 'request'"/>
            </template>
        </CommonModal>

    </div>
</template>

<script>
import Datatable from '@/components/datatable/Datatable'
import HousingReservationsListService from '@/services/HousingReservationsListService'
import qs from 'qs'

import moment from "moment/moment";
import RequestForm from "@/modules/housingReservationsList/pages/RequestForm.vue";
import TenantHeader from "@/modules/tenantReservationsList/pages/TeanatHeader.vue"

export default {
    components: {
        RequestForm,
        Datatable,
        TenantHeader
    },
    metaInfo() {
        return {
            title: this.$t('my_room_reservation')
        }
    },
    data() {
        return {
            tenantUser:{},
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('add_request_form'),
                                class: 'ri-edit-box-line',
                                callback: (row) => {
                                    this.requestFormShow(row)
                                },
                                show:(row)=>{
                                    const currentDate = new Date();
                                    const formatedDate = moment(currentDate).format('YYYY-MM-DD');
                                    return (row.check_out_date >= formatedDate) && row.status === 'paid';
                                }
                            },
                        ],

                    },
                    {
                        label: this.$t('check_in_date'),
                        field: 'check_in_date',
                        sortable: true
                    },
                    {
                        label: this.$t('check_out_date'),
                        field: 'check_out_date',
                        sortable: true
                    },
                    {
                        label: this.$t('building_name'),
                        field: 'building_name',
                        sortable: true
                    },
                    {
                        label: this.$t('room_name'),
                        field: 'room_name',
                        sortable: true
                    },
                    {
                        label: this.$t('housing_beds'),
                        field: 'bed_name',
                        sortable: true
                    },

                    {
                        label: this.$t('monthly_fee'),
                        field: 'fee',
                        sortable: true,
                        formatFn: (row) => {
                            const fee = row;
                            const formatter = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            });
                            return formatter.format(fee);
                        }
                    },
                    {
                        label: this.$t('total_amount'),
                        field: 'total_amount',
                        sortable: true,
                        formatFn: (row) => {
                            const fee = row;
                            const formatter = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            });
                            return formatter.format(fee);
                        }

                    },
                    {
                        label: this.$t('status'),
                        field: 'status_name',
                        sortable: true
                    },
                ],
                rows: [],
                total: 0,
                showTable: true,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            },
            formLoading: false,
            formId: null,
            formProcess: null,
        }
    },
    created() {
        this.getRows();
        this.tenantUserInformation()

    },
    methods: {
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type === 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        async tenantUserInformation() {
            const tenantUsers =localStorage.getItem('tenant_user')?JSON.parse(localStorage.getItem('tenant_user')):{}
            this.tenantUser=tenantUsers

        },
        async getRows() {
            const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return HousingReservationsListService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .catch((e) => {
                    this.showErrors(e);
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        requestFormShow(row) {
            this.formId = row.id
            this.formData=row
            this.formProcess = 'request'
            this.$refs.requestFormModal.$refs.commonModal.show()
        },

        requestFormSuccess() {
            this.$refs.requestFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
    }
}
</script>

<style scoped>
/* Responsive Styles */
.table-responsive {
    overflow-x: auto;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.wh-40-img {
    width: 40px;
    height: 40px;
    background-size: cover;
    background-position: center;
}

/* Make the logo responsive */
.logo-wrapper img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 768px) {
    .text-right {
        text-align: center;
    }

    .wh-40-img {
        width: 30px;
        height: 30px;
    }
}
.d-flex {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}


</style>
